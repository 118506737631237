<template>
  <el-dialog
    :title="detailData && detailData.id ? '编辑' : '添加'"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    destroy-on-close
    width="40%"
  >
    <div style="max-height: 500px; overflow-y: auto">
      <el-form ref="dataForm" :model="dataForm" :rules="rules">
        <CardBox title="基础信息">
          <el-form-item prop="companyName">
            <TextInput
              v-model="dataForm.companyName"
              :config="{
                type: 'input',
                required: true,
                label: '企业名称：',
                width: '100%',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="industry">
            <TextInput
              v-model="dataForm.industry"
              @returnVal="returnVal"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'radio',
                required: true,
                label: '所属行业：',
                width: '100%',
                modelTextKey: 'industryName',
                option: sdTrafficIndustry,
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item
            v-if="dataForm.industry == 1"
            prop="transportTypeKey"
            :rules="[
              {
                required: true,
                message: '请选择运输类型',
                trigger: 'change',
              },
            ]"
          >
            <TextInput
              v-model="dataForm.transportTypeKey"
              @returnVal="returnVal"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'radio',
                required: true,
                label: '运输类型：',
                width: '100%',
                modelTextKey: 'transportTypeName',
                option: sdTrafficTransportType,
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item
            v-if="dataForm.industry == 3 || dataForm.industry == 2"
            prop="isDangerousGoods"
          >
            <TextInput
              v-if="dataForm.industry == 2"
              v-model="dataForm.isDangerousGoods"
              :config="{
                type: 'radio',
                label: '是否危险货物运输：',
                width: '100%',
                option: [
                  {
                    label: '是',
                    value: 1,
                  },
                  {
                    label: '否',
                    value: 0,
                  },
                ],
              }"
            >
            </TextInput>
            <TextInput
              v-if="dataForm.industry == 3"
              v-model="dataForm.isDangerousGoods"
              :config="{
                type: 'radio',
                label: '是否危险货物码头：',
                width: '100%',
                option: [
                  {
                    label: '是',
                    value: 1,
                  },
                  {
                    label: '否',
                    value: 0,
                  },
                ],
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item
            prop="deviceCount"
            v-if="dataForm.industry == 1 || dataForm.industry == 2"
          >
            <TextInput
              v-model="dataForm.deviceCount"
              :config="{
                type: 'inputNumber',
                required: true,
                label: dataForm.industry == 1 ? '车辆数量：' : '船舶数量：',
                width: '100%',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item
            v-if="dataForm.industry == 1"
            prop="deviceType"
            :rules="[
              {
                required: true,
                message: '请选择运营车辆类型',
                trigger: 'change',
              },
            ]"
          >
            <TextInput
              v-model="dataForm.deviceType"
              @returnVal="returnVal"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'select',
                required: true,
                label: '运营车辆类型：',
                width: '100%',
                modelTextKey: 'deviceTypeName',
                option: sdTrafficVehicleType,
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="address">
            <TextInput
              v-model="dataForm.address"
              @getLocation="getLocation"
              :config="{
                modelKey: 'address',
                type: 'location',
                required: true,
                label: '所属地址：',
                width: '100%',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="contactPerson">
            <TextInput
              v-model="dataForm.contactPerson"
              :config="{
                type: 'input',
                required: true,
                label: '联系人：',
                width: '100%',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="contactPhone">
            <TextInput
              v-model="dataForm.contactPhone"
              :config="{
                type: 'input',
                required: true,
                label: '联系电话：',
                width: '100%',
              }"
            >
            </TextInput>
          </el-form-item>
        </CardBox>
        <CardBox v-if="dataForm.industry == 5" title="工程项目信息">
          <el-form-item prop="constructionUnit">
            <TextInput
              v-model="dataForm.constructionUnit"
              :config="{
                type: 'input',
                label: '建设单位：',
                width: '100%',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="contractAmount">
            <TextInput
              v-model="dataForm.contractAmount"
              :config="{
                type: 'input',
                label: '合同金额(万元)：',
                width: '100%',
                isNum: true,
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="completionDate">
            <TextInput
              v-model="dataForm.completionDate"
              :config="{
                type: 'datePicker',
                label: '竣工时间：',
                width: '100%',
              }"
            >
            </TextInput>
          </el-form-item>
        </CardBox>
        <CardBox title="投保信息">
          <el-form-item prop="insured">
            <TextInput
              v-model="dataForm.insured"
              :config="{
                type: 'radio',
                label: '是否有投保安全生产责任保险：',
                width: '100%',
                option: [
                  {
                    label: '是',
                    value: 1,
                  },
                  {
                    label: '否',
                    value: 0,
                  },
                ],
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item
            v-if="dataForm.insured == 1"
            prop="insuranceCompany"
            :rules="[
              { required: true, message: '请输入保险机构', trigger: 'change' },
            ]"
          >
            <TextInput
              v-model="dataForm.insuranceCompany"
              :config="{
                type: 'input',
                required: true,
                label: '保险机构：',
                width: '100%',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item
            v-if="dataForm.insured == 1"
            prop="insPolicyAttachmentList"
            :rules="[
              { required: true, message: '请添加电子保单', trigger: 'change' },
            ]"
          >
            <TextInput
              :config="{
                type: 'slot',
                required: true,
                label: '电子保单：',
                width: '100%',
              }"
              ><div>
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    v-for="(img, index) in dataForm.insPolicyAttachmentList"
                    style="position: relative; width: 100px; margin-right: 16px"
                    @click="getArrayBuffer(img.attachmentUrl)"
                  >
                    <!-- <img class="upload" :src="img.attachmentUrl" />
                    <i
                      @click="deletImg(index)"
                      class="el-icon-error delet-img"
                    ></i> -->
                    <FileIcon
                      class="upload"
                      :suffix="img.fileType"
                      :fileUrl="img.attachmentUrl"
                      :fileName="img.attachmentName"
                      @deletFile="deletImg(index)"
                    />
                    <div style="width: 100%">
                      {{ img.attachmentName }}
                    </div>
                  </div>
                  <div
                    @click="uploadFun"
                    class="upload"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </div>
            </TextInput>
          </el-form-item>
          <el-form-item prop="insured" v-if="dataForm.insured == 1">
            <TextInput
              v-model="dataForm.isDevelopAccidentPreventionServices"
              :config="{
                type: 'radio',
                required: true,
                label: '近6个月保险公司是否为本企业开展事故预防服务：',
                width: '100%',
                option: [
                  {
                    label: '是',
                    value: 1,
                  },
                  {
                    label: '否',
                    value: 0,
                  },
                ],
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item
            v-if="
              dataForm.insured == 1 &&
              dataForm.isDevelopAccidentPreventionServices
            "
            prop="serviceReportList"
            :rules="[
              { required: true, message: '请添加服务报告', trigger: 'change' },
            ]"
          >
            <TextInput
              :config="{
                type: 'slot',
                required: true,
                label: '服务报告：',
                width: '100%',
              }"
              ><div>
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    v-for="(img, index) in dataForm.serviceReportList"
                    style="position: relative; width: 100px; margin-right: 16px"
                    @click="getArrayBuffer(img.attachmentUrl)"
                  >
                    <!-- <img class="upload" :src="img.attachmentUrl" />
                    <i
                      @click="deletImg(index)"
                      class="el-icon-error delet-img"
                    ></i> -->
                    <FileIcon
                      class="upload"
                      :suffix="img.fileType"
                      :fileUrl="img.attachmentUrl"
                      :fileName="img.attachmentName"
                      @deletFile="deletImg2(index)"
                    />
                    <div style="width: 100%">
                      {{ img.attachmentName }}
                    </div>
                  </div>
                  <div
                    @click="uploadFun2"
                    class="upload"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </div>
            </TextInput>
          </el-form-item>
        </CardBox>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
      <el-button @click="submitForm" type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
import CardBox from "@/components/SimpleTable/CardBox.vue";
import FileIcon from "@/components/DragMultipleUpload/FileIcon2.vue";
import {
  trafficArticleEnterpriseEdit,
  trafficArticleEnterpriseDetail,
} from "@/api/safeDuty";
import {
  sdTrafficIndustry,
  sdTrafficVehicleType,
  sdTrafficTransportType,
} from "../js/const.js";
import _ from "lodash";
import { uploadFile, downloadFileLink } from "@/utils/index";
import { OSSDirectPass } from "@/api/oss.js";
export default {
  name: "TrafficFirmDialog",
  components: { TextInput, CardBox, FileIcon },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(n) {
      if (n && this.detailData.id) {
        trafficArticleEnterpriseDetail({ companyId: this.detailData.id }).then(
          (res) => {
            this.dataForm = res.data;
          }
        );
      } else {
        this.dataForm = {};
      }
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
    // detailData: {
    //   deep: true,
    //   handler(n) {
    //     if (n) {
    //       this.dataForm = _.cloneDeep(n);
    //     }
    //   },
    // },
  },
  data() {
    return {
      dialogVisible: false,
      sdTrafficIndustry: sdTrafficIndustry.filter((d) => d.dictKey !== ""),
      sdTrafficVehicleType: sdTrafficVehicleType.filter(
        (d) => d.dictKey !== ""
      ),
      sdTrafficTransportType: sdTrafficTransportType.filter(
        (d) => d.dictKey !== ""
      ),
      dataForm: {
        address: "",
        city: "",
        companyName: "",
        completionDate: "",
        constructionUnit: "",
        contactPerson: "",
        contactPhone: "",
        contractAmount: 0,
        deviceCount: 0,
        deviceType: "",
        deviceTypeName: "",
        district: "",
        industry: "",
        industryName: "",
        insPolicyAttachmentList: [
          // {
          //   attachmentName: "",
          //   attachmentType: "",
          //   attachmentUrl: "",
          //   bussId: 0,
          //   createTime: "",
          //   createUser: 0,
          //   fileType: "",
          //   id: 0,
          //   isDeleted: 0,
          //   orderType: 0,
          //   sort: 0,
          //   tenantId: "",
          //   updateTime: "",
          //   updateUser: 0,
          // },
        ],
        serviceReportList: [],
        transportTypeKey: "",
        transportTypeName: "",
        isDangerousGoods: "",
        isDevelopAccidentPreventionServices: 0,
        insuranceCompany: "",
        insured: "",
        latitude: "",
        longitude: "",
        province: "",
        userId: 0,
        userName: "",
        userPhone: "",
      },
      rules: {
        companyName: [
          {
            required: true,
            message: "请填写公司名称",
            trigger: "change",
          },
        ],
        industry: [
          {
            required: true,
            message: "请填写所属行业",
            trigger: "change",
          },
        ],
        deviceCount: [
          {
            required: true,
            message: "请填写车辆数量",
            trigger: "change",
          },
        ],
        // deviceType: [
        //   {
        //     required: true,
        //     message: "请选择运营车辆类型",
        //     trigger: "change",
        //   },
        // ],
        address: [
          {
            required: true,
            message: "请填写所属地址",
            trigger: "change",
          },
        ],
        contactPerson: [
          {
            required: true,
            message: "请填写联系人",
            trigger: "change",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "请填写联系电话",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    getArrayBuffer(url) {
      downloadFileLink(url);
    },
    //清空
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
    },
    async uploadFun() {
      uploadFile({ accept: "*", fileSize: 10 }).then(async (res) => {
        //console.log(res);
        let url = await OSSDirectPass({
          name: res.name,
          raw: res,
        });
        this.dataForm.insPolicyAttachmentList.push({
          attachmentName: res.name,
          attachmentType: "EP",
          attachmentUrl: url,
          fileType: res.name?.split(".")?.pop(),
        });
        this.$forceUpdate();
        //this.$emit("submit");
      });
    },
    async uploadFun2() {
      uploadFile({ accept: "*", fileSize: 10 }).then(async (res) => {
        //console.log(res);
        let url = await OSSDirectPass({
          name: res.name,
          raw: res,
        });
        this.dataForm.serviceReportList.push({
          attachmentName: res.name,
          attachmentType: "service_report_file",
          attachmentUrl: url,
          fileType: res.name?.split(".")?.pop(),
        });
        this.$forceUpdate();
        //this.$emit("submit");
      });
    },
    deletImg(index) {
      this.dataForm.insPolicyAttachmentList.splice(index, 1);
    },
    deletImg2(index) {
      this.dataForm.serviceReportList.splice(index, 1);
    },
    returnVal(res) {
      this.dataForm[res.type] = res.data.dictValue;
    },
    getLocation(e) {
      this.dataForm.province = e.province;
      this.dataForm.city = e.city;
      this.dataForm.district = e.district;
      this.dataForm.latitude = "";
      this.dataForm.longitude = "";
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          trafficArticleEnterpriseEdit(this.dataForm).then((res) => {
            this.$emit("submit");
            this.clearForm();
            this.dialogVisible = false;
            this.$message({
              message: "添加或编辑成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed #dee3e6;
  position: relative;
}
.delet-img {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
