var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.detailData && _vm.detailData.id ? '编辑' : '添加',"visible":_vm.dialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"destroy-on-close":"","width":"40%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticStyle:{"max-height":"500px","overflow-y":"auto"}},[_c('el-form',{ref:"dataForm",attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('CardBox',{attrs:{"title":"基础信息"}},[_c('el-form-item',{attrs:{"prop":"companyName"}},[_c('TextInput',{attrs:{"config":{
              type: 'input',
              required: true,
              label: '企业名称：',
              width: '100%',
            }},model:{value:(_vm.dataForm.companyName),callback:function ($$v) {_vm.$set(_vm.dataForm, "companyName", $$v)},expression:"dataForm.companyName"}})],1),_c('el-form-item',{attrs:{"prop":"industry"}},[_c('TextInput',{attrs:{"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            },"config":{
              type: 'radio',
              required: true,
              label: '所属行业：',
              width: '100%',
              modelTextKey: 'industryName',
              option: _vm.sdTrafficIndustry,
            }},on:{"returnVal":_vm.returnVal},model:{value:(_vm.dataForm.industry),callback:function ($$v) {_vm.$set(_vm.dataForm, "industry", $$v)},expression:"dataForm.industry"}})],1),(_vm.dataForm.industry == 1)?_c('el-form-item',{attrs:{"prop":"transportTypeKey","rules":[
            {
              required: true,
              message: '请选择运输类型',
              trigger: 'change',
            },
          ]}},[_c('TextInput',{attrs:{"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            },"config":{
              type: 'radio',
              required: true,
              label: '运输类型：',
              width: '100%',
              modelTextKey: 'transportTypeName',
              option: _vm.sdTrafficTransportType,
            }},on:{"returnVal":_vm.returnVal},model:{value:(_vm.dataForm.transportTypeKey),callback:function ($$v) {_vm.$set(_vm.dataForm, "transportTypeKey", $$v)},expression:"dataForm.transportTypeKey"}})],1):_vm._e(),(_vm.dataForm.industry == 3 || _vm.dataForm.industry == 2)?_c('el-form-item',{attrs:{"prop":"isDangerousGoods"}},[(_vm.dataForm.industry == 2)?_c('TextInput',{attrs:{"config":{
              type: 'radio',
              label: '是否危险货物运输：',
              width: '100%',
              option: [
                {
                  label: '是',
                  value: 1,
                },
                {
                  label: '否',
                  value: 0,
                },
              ],
            }},model:{value:(_vm.dataForm.isDangerousGoods),callback:function ($$v) {_vm.$set(_vm.dataForm, "isDangerousGoods", $$v)},expression:"dataForm.isDangerousGoods"}}):_vm._e(),(_vm.dataForm.industry == 3)?_c('TextInput',{attrs:{"config":{
              type: 'radio',
              label: '是否危险货物码头：',
              width: '100%',
              option: [
                {
                  label: '是',
                  value: 1,
                },
                {
                  label: '否',
                  value: 0,
                },
              ],
            }},model:{value:(_vm.dataForm.isDangerousGoods),callback:function ($$v) {_vm.$set(_vm.dataForm, "isDangerousGoods", $$v)},expression:"dataForm.isDangerousGoods"}}):_vm._e()],1):_vm._e(),(_vm.dataForm.industry == 1 || _vm.dataForm.industry == 2)?_c('el-form-item',{attrs:{"prop":"deviceCount"}},[_c('TextInput',{attrs:{"config":{
              type: 'inputNumber',
              required: true,
              label: _vm.dataForm.industry == 1 ? '车辆数量：' : '船舶数量：',
              width: '100%',
            }},model:{value:(_vm.dataForm.deviceCount),callback:function ($$v) {_vm.$set(_vm.dataForm, "deviceCount", $$v)},expression:"dataForm.deviceCount"}})],1):_vm._e(),(_vm.dataForm.industry == 1)?_c('el-form-item',{attrs:{"prop":"deviceType","rules":[
            {
              required: true,
              message: '请选择运营车辆类型',
              trigger: 'change',
            },
          ]}},[_c('TextInput',{attrs:{"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            },"config":{
              type: 'select',
              required: true,
              label: '运营车辆类型：',
              width: '100%',
              modelTextKey: 'deviceTypeName',
              option: _vm.sdTrafficVehicleType,
            }},on:{"returnVal":_vm.returnVal},model:{value:(_vm.dataForm.deviceType),callback:function ($$v) {_vm.$set(_vm.dataForm, "deviceType", $$v)},expression:"dataForm.deviceType"}})],1):_vm._e(),_c('el-form-item',{attrs:{"prop":"address"}},[_c('TextInput',{attrs:{"config":{
              modelKey: 'address',
              type: 'location',
              required: true,
              label: '所属地址：',
              width: '100%',
            }},on:{"getLocation":_vm.getLocation},model:{value:(_vm.dataForm.address),callback:function ($$v) {_vm.$set(_vm.dataForm, "address", $$v)},expression:"dataForm.address"}})],1),_c('el-form-item',{attrs:{"prop":"contactPerson"}},[_c('TextInput',{attrs:{"config":{
              type: 'input',
              required: true,
              label: '联系人：',
              width: '100%',
            }},model:{value:(_vm.dataForm.contactPerson),callback:function ($$v) {_vm.$set(_vm.dataForm, "contactPerson", $$v)},expression:"dataForm.contactPerson"}})],1),_c('el-form-item',{attrs:{"prop":"contactPhone"}},[_c('TextInput',{attrs:{"config":{
              type: 'input',
              required: true,
              label: '联系电话：',
              width: '100%',
            }},model:{value:(_vm.dataForm.contactPhone),callback:function ($$v) {_vm.$set(_vm.dataForm, "contactPhone", $$v)},expression:"dataForm.contactPhone"}})],1)],1),(_vm.dataForm.industry == 5)?_c('CardBox',{attrs:{"title":"工程项目信息"}},[_c('el-form-item',{attrs:{"prop":"constructionUnit"}},[_c('TextInput',{attrs:{"config":{
              type: 'input',
              label: '建设单位：',
              width: '100%',
            }},model:{value:(_vm.dataForm.constructionUnit),callback:function ($$v) {_vm.$set(_vm.dataForm, "constructionUnit", $$v)},expression:"dataForm.constructionUnit"}})],1),_c('el-form-item',{attrs:{"prop":"contractAmount"}},[_c('TextInput',{attrs:{"config":{
              type: 'input',
              label: '合同金额(万元)：',
              width: '100%',
              isNum: true,
            }},model:{value:(_vm.dataForm.contractAmount),callback:function ($$v) {_vm.$set(_vm.dataForm, "contractAmount", $$v)},expression:"dataForm.contractAmount"}})],1),_c('el-form-item',{attrs:{"prop":"completionDate"}},[_c('TextInput',{attrs:{"config":{
              type: 'datePicker',
              label: '竣工时间：',
              width: '100%',
            }},model:{value:(_vm.dataForm.completionDate),callback:function ($$v) {_vm.$set(_vm.dataForm, "completionDate", $$v)},expression:"dataForm.completionDate"}})],1)],1):_vm._e(),_c('CardBox',{attrs:{"title":"投保信息"}},[_c('el-form-item',{attrs:{"prop":"insured"}},[_c('TextInput',{attrs:{"config":{
              type: 'radio',
              label: '是否有投保安全生产责任保险：',
              width: '100%',
              option: [
                {
                  label: '是',
                  value: 1,
                },
                {
                  label: '否',
                  value: 0,
                },
              ],
            }},model:{value:(_vm.dataForm.insured),callback:function ($$v) {_vm.$set(_vm.dataForm, "insured", $$v)},expression:"dataForm.insured"}})],1),(_vm.dataForm.insured == 1)?_c('el-form-item',{attrs:{"prop":"insuranceCompany","rules":[
            { required: true, message: '请输入保险机构', trigger: 'change' },
          ]}},[_c('TextInput',{attrs:{"config":{
              type: 'input',
              required: true,
              label: '保险机构：',
              width: '100%',
            }},model:{value:(_vm.dataForm.insuranceCompany),callback:function ($$v) {_vm.$set(_vm.dataForm, "insuranceCompany", $$v)},expression:"dataForm.insuranceCompany"}})],1):_vm._e(),(_vm.dataForm.insured == 1)?_c('el-form-item',{attrs:{"prop":"insPolicyAttachmentList","rules":[
            { required: true, message: '请添加电子保单', trigger: 'change' },
          ]}},[_c('TextInput',{attrs:{"config":{
              type: 'slot',
              required: true,
              label: '电子保单：',
              width: '100%',
            }}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_vm._l((_vm.dataForm.insPolicyAttachmentList),function(img,index){return _c('div',{staticStyle:{"position":"relative","width":"100px","margin-right":"16px"},on:{"click":function($event){return _vm.getArrayBuffer(img.attachmentUrl)}}},[_c('FileIcon',{staticClass:"upload",attrs:{"suffix":img.fileType,"fileUrl":img.attachmentUrl,"fileName":img.attachmentName},on:{"deletFile":function($event){return _vm.deletImg(index)}}}),_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(img.attachmentName)+" ")])],1)}),_c('div',{staticClass:"upload",staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},on:{"click":_vm.uploadFun}},[_c('i',{staticClass:"el-icon-plus"})])],2)])])],1):_vm._e(),(_vm.dataForm.insured == 1)?_c('el-form-item',{attrs:{"prop":"insured"}},[_c('TextInput',{attrs:{"config":{
              type: 'radio',
              required: true,
              label: '近6个月保险公司是否为本企业开展事故预防服务：',
              width: '100%',
              option: [
                {
                  label: '是',
                  value: 1,
                },
                {
                  label: '否',
                  value: 0,
                },
              ],
            }},model:{value:(_vm.dataForm.isDevelopAccidentPreventionServices),callback:function ($$v) {_vm.$set(_vm.dataForm, "isDevelopAccidentPreventionServices", $$v)},expression:"dataForm.isDevelopAccidentPreventionServices"}})],1):_vm._e(),(
            _vm.dataForm.insured == 1 &&
            _vm.dataForm.isDevelopAccidentPreventionServices
          )?_c('el-form-item',{attrs:{"prop":"serviceReportList","rules":[
            { required: true, message: '请添加服务报告', trigger: 'change' },
          ]}},[_c('TextInput',{attrs:{"config":{
              type: 'slot',
              required: true,
              label: '服务报告：',
              width: '100%',
            }}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_vm._l((_vm.dataForm.serviceReportList),function(img,index){return _c('div',{staticStyle:{"position":"relative","width":"100px","margin-right":"16px"},on:{"click":function($event){return _vm.getArrayBuffer(img.attachmentUrl)}}},[_c('FileIcon',{staticClass:"upload",attrs:{"suffix":img.fileType,"fileUrl":img.attachmentUrl,"fileName":img.attachmentName},on:{"deletFile":function($event){return _vm.deletImg2(index)}}}),_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(img.attachmentName)+" ")])],1)}),_c('div',{staticClass:"upload",staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},on:{"click":_vm.uploadFun2}},[_c('i',{staticClass:"el-icon-plus"})])],2)])])],1):_vm._e()],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }