<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          icon="el-icon-upload2"
          class="export-btn"
          @click="exportData(data.params)"
          >导出</el-button
        ></template
      >
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toDetail(data.row, 0)"
          >查看详情</el-button
        >
        <el-button type="text" @click="toDetail(data.row)">编辑</el-button>
        <el-button
          @click="firmDelete(data.row)"
          type="text"
          style="color: #f64a2d"
          >删除</el-button
        >
      </div>
      <div slot="insured" slot-scope="{ data }">
        <span v-if="data.row.insured" style="color: #00bc0d">已投保</span>
        <span v-else style="color: #f64a2d">未投保</span>
      </div>
      <div slot="isDevelopAccidentPreventionServices" slot-scope="{ data }">
        <span
          v-if="data.row.isDevelopAccidentPreventionServices"
          style="color: #00bc0d"
          >是</span
        >
        <span v-else style="color: #f64a2d">否</span>
      </div>
    </SimpleTable>
    <TrafficFirmDialog
      :show.sync="show"
      :detailData="detailData"
      @submit="$refs.table.getData()"
    ></TrafficFirmDialog>
    <TrafficFirmDetailsDialog
      :show.sync="detailShow"
      :detailData="detailData"
    ></TrafficFirmDetailsDialog>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import TrafficFirmDialog from "../components/TrafficFirmDialog";
import TrafficFirmDetailsDialog from "../components/TrafficFirmDetailsDialog";
import {
  trafficArticleEnterpriseList,
  trafficArticleEnterpriseDelete,
  trafficArticleEnterpriseExport,
} from "@/api/safeDuty.js";
import { sdTrafficIndustry } from "../js/const";
export default {
  components: { SimpleTable, TrafficFirmDialog, TrafficFirmDetailsDialog },
  data() {
    return {
      show: false,
      detailShow: false,
      detailData: {},
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "index",
        currentSizeKey: "size",
        totalKey: "total",
        height: 560,
        searchConfigs: [
          {
            label: "企业名称",
            type: "input",
            modelKey: "companyName",
          },
          {
            label: "地址",
            type: "input",
            modelKey: "address",
          },
          {
            label: "所属行业",
            type: "select",
            modelKey: "industry",
            option: sdTrafficIndustry,
          },
          {
            label: "上传用户",
            type: "input",
            modelKey: "userName",
          },
          {
            label: "上传时间",
            type: "daterange",
            daterangeKeys: ["createTimeStart", "createTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createT",
          },
          {
            label: "联系人",
            type: "input",
            modelKey: "contactPerson",
          },
          {
            label: "是否投保",
            type: "select",
            modelKey: "insured",
            option: [
              { dictKey: "", dictValue: "全部" },
              { dictKey: 1, dictValue: "是" },
              { dictKey: 0, dictValue: "否" },
            ],
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "companyName",
            label: "企业名称",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "industryName",
            label: "所属行业",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "address",
            label: "地址",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "contactPerson",
            label: "联系人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "contactPhone",
            label: "联系电话",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "userName",
            label: "上传用户",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "userPhone",
            label: "上传用户电话",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insured",
            label: "是否投保",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "isDevelopAccidentPreventionServices",
            label: "近6个月保险公司是否为本企业开展事故预防服务",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "createTime",
            label: "提交时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
        ],
      },
    };
  },
  methods: {
    async queryFun(queryParams) {
      let res = await trafficArticleEnterpriseList(queryParams);
      return {
        localData: res.data.records || [],
        total: res.data.total,
      };
    },
    // 跳转详情或编辑
    toDetail(item, type = 1) {
      this.detailData = item;
      if (type == 1) {
        this.show = true;
      } else {
        this.detailShow = true;
      }
    },
    firmDelete(item) {
      this.$confirm("删除后对应数据也将清理，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        trafficArticleEnterpriseDelete({ companyId: item.id }).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    exportData(p) {
      trafficArticleEnterpriseExport(p);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.dele-btn {
  background: #c94242;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #c94242;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
