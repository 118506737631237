<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    destroy-on-close
    width="40%"
  >
    <div style="max-height: 500px; overflow-y: auto">
      <div>
        <div class="data-info">
          <div class="data-info-item">
            <div class="data-info-item-title">上传用户</div>
            <div class="data-info-item-value">
              {{ dataForm.userName || "---" }}
            </div>
          </div>
          <div class="data-info-item">
            <div class="data-info-item-title">上传用户电话</div>
            <div class="data-info-item-value">
              {{ dataForm.userPhone || "---" }}
            </div>
          </div>
          <div class="data-info-item">
            <div class="data-info-item-title">上传时间</div>
            <div class="data-info-item-value">
              {{ dataForm.createTime || "---" }}
            </div>
          </div>
        </div>
      </div>
      <CardBox title="基础信息">
        <div class="data-info">
          <div class="data-info-item">
            <div class="data-info-item-title">企业名称</div>
            <div class="data-info-item-value">
              {{ dataForm.companyName || "---" }}
            </div>
          </div>
          <div class="data-info-item">
            <div class="data-info-item-title">所属行业</div>
            <div class="data-info-item-value">
              {{ dataForm.industryName || "---" }}
            </div>
          </div>
          <div v-if="dataForm.industry == 1" class="data-info-item">
            <div class="data-info-item-title">运输类型</div>
            <div class="data-info-item-value">
              {{ dataForm.transportTypeName || "---" }}
            </div>
          </div>
          <div
            v-if="dataForm.industry == 2 || dataForm.industry == 3"
            class="data-info-item"
          >
            <div v-if="dataForm.industry == 2" class="data-info-item-title">
              是否危险货物运输
            </div>
            <div v-if="dataForm.industry == 3" class="data-info-item-title">
              是否危险货物码头
            </div>
            <div class="data-info-item-value">
              {{ dataForm.isDangerousGoods ? "是" : "否" }}
            </div>
          </div>
          <div class="data-info-item">
            <div class="data-info-item-title">地址</div>
            <div class="data-info-item-value">
              {{ dataForm.address || "---" }}
            </div>
          </div>
          <div class="data-info-item">
            <div class="data-info-item-title">联系人</div>
            <div class="data-info-item-value">
              {{ dataForm.contactPerson || "---" }}
            </div>
          </div>
          <div class="data-info-item">
            <div class="data-info-item-title">联系电话</div>
            <div class="data-info-item-value">
              {{ dataForm.contactPhone || "---" }}
            </div>
          </div>
        </div>
      </CardBox>
      <CardBox v-if="dataForm.industry == 5" title="工程项目信息">
        <div class="data-info">
          <div class="data-info-item">
            <div class="data-info-item-title">建设单位</div>
            <div class="data-info-item-value">
              {{ dataForm.constructionUnit || "---" }}
            </div>
          </div>
          <div class="data-info-item">
            <div class="data-info-item-title">竣工时间</div>
            <div class="data-info-item-value">
              {{ dataForm.completionDate || "---" }}
            </div>
          </div>
          <div class="data-info-item">
            <div class="data-info-item-title">合同金额(万元)</div>
            <div class="data-info-item-value">
              {{ dataForm.contractAmount || "---" }}
            </div>
          </div>
        </div>
      </CardBox>
      <CardBox title="投保信息">
        <div class="data-info">
          <div class="data-info-item">
            <div class="data-info-item-title">是否有投保安全生产责任保险</div>
            <div class="data-info-item-value">
              {{ dataForm.insured ? "是" : "否" }}
            </div>
          </div>
          <div v-if="dataForm.insured" class="data-info-item">
            <div class="data-info-item-title">投保机构</div>
            <div class="data-info-item-value">
              {{ dataForm.insuranceCompany || "---" }}
            </div>
          </div>
          <div v-if="dataForm.insured" class="data-info-item">
            <div class="data-info-item-title">
              近6个月保险公司是否为本企业开展事故预防服务
            </div>
            <div class="data-info-item-value">
              {{ dataForm.isDevelopAccidentPreventionServices ? "是" : "否" }}
            </div>
          </div>
          <div
            v-if="dataForm.insured"
            class="data-info-item"
            style="flex-direction: column; width: 100%"
          >
            <div
              class="data-info-item-title"
              style="text-align: left; margin-bottom: 10px"
            >
              电子保单
            </div>
            <div class="data-info-item-value" style="display: flex">
              <div
                v-for="(img, index) in dataForm.insPolicyAttachmentList"
                class="upload"
                @click="downloadFileLink(img.attachmentUrl)"
              >
                <!-- <img class="upload" :src="img.attachmentUrl" /> -->
                <FileIcon
                  :suffix="img.fileType"
                  :fileUrl="img.attachmentUrl"
                  :fileName="img.attachmentName"
                  :showDelet="false"
                />
                <div style="width: 100%; height: 16px; overflow: hidden">
                  {{ img.attachmentName }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              dataForm.insured && dataForm.isDevelopAccidentPreventionServices
            "
            class="data-info-item"
            style="flex-direction: column; width: 100%; margin-top: 50px"
          >
            <div
              class="data-info-item-title"
              style="text-align: left; margin-bottom: 10px"
            >
              服务报告
            </div>
            <div class="data-info-item-value" style="display: flex">
              <div
                v-for="(img, index) in dataForm.serviceReportList"
                class="upload"
                @click="downloadFileLink(img.attachmentUrl)"
              >
                <!-- <img class="upload" :src="img.attachmentUrl" /> -->
                <FileIcon
                  :suffix="img.fileType"
                  :fileUrl="img.attachmentUrl"
                  :fileName="img.attachmentName"
                  :showDelet="false"
                />
                <div style="width: 100%; height: 16px; overflow: hidden">
                  {{ img.attachmentName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBox>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
      <!-- <el-button @click="submitForm" type="primary">确 定</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
import CardBox from "@/components/SimpleTable/CardBox.vue";
import FileIcon from "@/components/DragMultipleUpload/FileIcon2.vue";
import { trafficArticleEnterpriseDetail } from "@/api/safeDuty";
import { downloadFileLink } from "@/utils/index";
// import _ from "lodash";
export default {
  name: "TrafficFirmDialog",
  components: { TextInput, CardBox, FileIcon },
  props: {
    title: {
      type: String,
      default: "企业详情",
    },
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(n) {
      if (n && this.detailData.id) {
        trafficArticleEnterpriseDetail({ companyId: this.detailData.id }).then(
          (res) => {
            this.dataForm = res.data;
          }
        );
      } else {
        this.dataForm = {};
      }
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
    // detailData: {
    //   deep: true,
    //   handler(n) {
    //     if (n) {
    //       this.dataForm = _.cloneDeep(n);
    //     }
    //   },
    // },
  },
  data() {
    return {
      downloadFileLink: downloadFileLink,
      dialogVisible: false,
      dataForm: {
        address: "",
        city: "",
        companyName: "",
        completionDate: "",
        constructionUnit: "",
        contactPerson: "",
        contactPhone: "",
        contractAmount: 0,
        deviceCount: 0,
        deviceType: "",
        deviceTypeName: "",
        district: "",
        industry: "",
        industryName: "",
        createTime: "",
        insPolicyAttachmentList: [
          // {
          //   attachmentName: "",
          //   attachmentType: "",
          //   attachmentUrl: "",
          //   bussId: 0,
          //   createTime: "",
          //   createUser: 0,
          //   fileType: "",
          //   id: 0,
          //   isDeleted: 0,
          //   orderType: 0,
          //   sort: 0,
          //   tenantId: "",
          //   updateTime: "",
          //   updateUser: 0,
          // },
        ],
        insuranceCompany: "",
        insured: "",
        latitude: "",
        longitude: "",
        province: "",
        userId: 0,
        userName: "",
        userPhone: "",
      },
    };
  },
  methods: {
    //清空
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
    },
    // async uploadFun() {
    //   uploadFile({ accept: "image/*", fileSize: 10 }).then(async (res) => {
    //     //console.log(res);
    //     let url = await OSSDirectPass({
    //       name: res.name,
    //       raw: res,
    //     });
    //     this.dataForm.insPolicyAttachmentList.push({
    //       attachmentName: res.name,
    //       attachmentType: "EP",
    //       attachmentUrl: url,
    //       fileType: res.name?.split(".")?.pop(),
    //     });
    //     this.$forceUpdate();
    //     this.$emit("submit");
    //   });
    // },
    // deletImg(index) {
    //   this.dataForm.insPolicyAttachmentList.splice(index, 1);
    // },
    handleClose(done) {
      this.clearForm();
      done();
    },
    // submitForm() {
    //   this.$refs.dataForm.validate((valid) => {
    //     if (valid) {
    //       trafficArticleEnterpriseEdit(this.dataForm).then((res) => {
    //         this.$emit("submit");
    //         this.clearForm();
    //         this.dialogVisible = false;
    //         this.$message({
    //           message: "添加或编辑成功",
    //           type: "success",
    //         });
    //       });
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed #dee3e6;
  margin-right: 16px;
  position: relative;
}
.data-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  &-item {
    display: flex;
    width: calc(50% - 16px);
    margin-top: 16px;
    &-title {
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      min-width: 100px;
      text-align: right;
    }
    &-value {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      margin-left: 14px;
      flex: 1;
    }
  }
}
</style>
